const { text } = require("stream/consumers");

function initialObturador(){
    gsap.registerPlugin(ScrollTrigger);

	let sections = gsap.utils.toArray(".panel-obt");

	gsap.to(sections, {
	xPercent: -100 * (sections.length - 1),
	ease: "none",
	scrollTrigger: {
		trigger: ".containerObt",
		pin: true,
		scrub: 8,
		snap: 1 / (sections.length),
		// base vertical scrolling on how wide the container is so it feels more natural.
		end: "-300"
	}
	});

}

function obturadorScroll(){
	
	gsap.registerPlugin(ScrollTrigger);

	setTimeout( () => {
		let container = document.getElementById("pinObturador");
		let slide = document.getElementById("slideObturador");
		if( container && slide ){	 

			let end = container.scrollWidth;
			let scrollTween = gsap.to(slide, {
				ease: "none", // <-- IMPORTANT!
				scrollTrigger: {
					trigger: container,
					start: `top top`,
					end: `+${end} bottom`,
					pin: true,
					scrub: 0.1,
					toggleActions: 'play'
				},
			});  

			let texts = container.querySelectorAll('div#slideObturador section');
			let top = container.offsetWidth;

			let widthWindow = window.innerWidth;
			if( widthWindow >= 1900 ){
				texts.forEach(text =>{
					gsap.to(text, {
						opacity: 0,
						duration: 1,
						scrollTrigger: {
							trigger: text,
							start: '+1800 top',
							end: '+1800 top',
							toggleActions: 'play none none none'
						}
					})
					gsap.to(text, {
						x: -text.offsetWidth,
						duration: 0,
						scrollTrigger: {
							trigger: text,
							start: '+1950 top',
							end: '+1950 top',
							toggleActions: 'play none none reverse'
						}
					})
					gsap.to(text, {
						opacity: 1,
						scrollTrigger: {
							trigger: text,
							start: '+2100 top',
							end: '+2100 top',
							toggleActions: 'play none none reverse'
						}
					});
					gsap.to(text, {
						opacity: 0,
						duration: 1,
						scrollTrigger: {
							trigger: text,
							start: '+3800 top',
							end: '+3800 top',
							toggleActions: 'play none none none'
						}
					})
					gsap.to(text, {
						x: -text.offsetWidth*2,
						duration: 0,
						scrollTrigger: {
							trigger: text,
							start: '+3950 top',
							end: '+3950 top',
							toggleActions: 'play none none reverse'
						}
					})
					gsap.to(text, {
						opacity: 1,
						scrollTrigger: {
							trigger: text,
							start: '+4100 top',
							toggleActions: 'play none none none'
						}
					});
				});
			}
			if( widthWindow >= 1024 && widthWindow < 1200 ){
				texts.forEach(text =>{
					gsap.to(text, {
						opacity: 0,
						duration: 1,
						scrollTrigger: {
							trigger: text,
							start: '+800 top',
							end: '+800 top',
							toggleActions: 'play none none none'
						}
					})
					gsap.to(text, {
						x: -text.offsetWidth,
						duration: 0,
						scrollTrigger: {
							trigger: text,
							start: '+950 top',
							end: '+950 top',
							toggleActions: 'play none none reverse'
						}
					})
					gsap.to(text, {
						opacity: 1,
						scrollTrigger: {
							trigger: text,
							start: '+1100 top',
							end: '+1100 top',
							toggleActions: 'play none none reverse'
						}
					});
					gsap.to(text, {
						opacity: 0,
						duration: 1,
						scrollTrigger: {
							trigger: text,
							start: '+1900 top',
							end: '+1900 top',
							toggleActions: 'play none none none'
						}
					})
					gsap.to(text, {
						x: -text.offsetWidth*2,
						duration: 0,
						scrollTrigger: {
							trigger: text,
							start: '+2050 top',
							end: '+2050 top',
							toggleActions: 'play none none reverse'
						}
					})
					gsap.to(text, {
						opacity: 1,
						scrollTrigger: {
							trigger: text,
							start: '+2200 top',
							toggleActions: 'play none none none'
						}
					});
				});
			}
			if( widthWindow >= 1200 && widthWindow < 1400 ){
				texts.forEach(text =>{
					gsap.to(text, {
						opacity: 0,
						duration: 1,
						scrollTrigger: {
							trigger: text,
							start: '+800 top',
							end: '+800 top',
							toggleActions: 'play none none none'
						}
					})
					gsap.to(text, {
						x: -text.offsetWidth,
						duration: 0,
						scrollTrigger: {
							trigger: text,
							start: '+950 top',
							end: '+950 top',
							toggleActions: 'play none none reverse'
						}
					})
					gsap.to(text, {
						opacity: 1,
						scrollTrigger: {
							trigger: text,
							start: '+1100 top',
							end: '+1100 top',
							toggleActions: 'play none none reverse'
						}
					});
					gsap.to(text, {
						opacity: 0,
						duration: 1,
						scrollTrigger: {
							trigger: text,
							start: '+1900 top',
							end: '+1900 top',
							toggleActions: 'play none none none'
						}
					})
					gsap.to(text, {
						x: -text.offsetWidth*2,
						duration: 0,
						scrollTrigger: {
							trigger: text,
							start: '+2050 top',
							end: '+2050 top',
							toggleActions: 'play none none reverse'
						}
					})
					gsap.to(text, {
						opacity: 1,
						scrollTrigger: {
							trigger: text,
							start: '+2200 top',
							toggleActions: 'play none none none'
						}
					});
				});
			}
			if( widthWindow >= 1400 && widthWindow < 1900 ){
				texts.forEach(text =>{
					gsap.to(text, {
						opacity: 0,
						duration: 1,
						scrollTrigger: {
							trigger: text,
							start: '+1000 top',
							end: '+1000 top',
							toggleActions: 'play none none none'
						}
					})
					gsap.to(text, {
						x: -text.offsetWidth,
						duration: 0,
						scrollTrigger: {
							trigger: text,
							start: '+1150 top',
							end: '+1150 top',
							toggleActions: 'play none none reverse'
						}
					})
					gsap.to(text, {
						opacity: 1,
						scrollTrigger: {
							trigger: text,
							start: '+1300 top',
							end: '+1300 top',
							toggleActions: 'play none none reverse'
						}
					});
					gsap.to(text, {
						opacity: 0,
						duration: 1,
						scrollTrigger: {
							trigger: text,
							start: '+2300 top',
							end: '+2300 top',
							toggleActions: 'play none none none'
						}
					})
					gsap.to(text, {
						x: -text.offsetWidth*2,
						duration: 0,
						scrollTrigger: {
							trigger: text,
							start: '+2450 top',
							end: '+2450 top',
							toggleActions: 'play none none reverse'
						}
					})
					gsap.to(text, {
						opacity: 1,
						scrollTrigger: {
							trigger: text,
							start: '+2600 top',
							toggleActions: 'play none none none'
						}
					});
				});
			}
		}
	}, 500)
}

function otherScroll(){
	
	gsap.registerPlugin(ScrollTrigger);

	setTimeout( () => {
		let container = document.getElementById("contenedor-principal");
		let texts = container.querySelectorAll('div.col-4 img');
		let end = container.scrollWidth * 1.25;
		let top = container.offsetWidth;
		let scrollTween = gsap.to(container, {
			x: -(container.scrollWidth - container.clientWidth),
			ease: "none", // <-- IMPORTANT!
			scrollTrigger: {
				trigger: container,
				start: `top`,
				end: `+${end} bottom`,
				pin: true,
				scrub: 0.1,
				toggleActions: 'play none none reverse'
			},
		});   
		texts.forEach(text =>{
			gsap.to(text, {
				scale: 1,
				duration: 1,
				scrollTrigger: {
					trigger: text,
					start: '-1000',
					containerAnimation: scrollTween,
					toggleActions: 'play none none reverse'
				}
			})
		});
		texts.forEach(text =>{
			gsap.to(text, {
				opacity: 0,
				duration: 1.05,
				scrollTrigger: {
					trigger: text,
					start: 'top',
					containerAnimation: scrollTween,
					toggleActions: 'play none none reverse'
				}
			});
		})
	}, 800)
}

function exampleScroll(){
	gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

/* Main navigation */
let panelsSection = document.querySelector("#panels"),
	panelsContainer = document.querySelector("#panels-container"),
	tween;
document.querySelectorAll(".anchor").forEach(anchor => {
	anchor.addEventListener("click", function(e) {
		e.preventDefault();
		let targetElem = document.querySelector(e.target.getAttribute("href")),
			y = targetElem;
		if (targetElem && panelsContainer.isSameNode(targetElem.parentElement)) {
			let totalScroll = tween.scrollTrigger.end - tween.scrollTrigger.start,
				totalMovement = (panels.length - 1) * targetElem.offsetWidth;
			y = Math.round(tween.scrollTrigger.start + (targetElem.offsetLeft / totalMovement) * totalScroll);
		}
		gsap.to(window, {
			scrollTo: {
				y: y,
				autoKill: false
			},
			duration: 1
		});
	});
});

const panels = gsap.utils.toArray("#panels-container .panel");
tween = gsap.to(panels, {
	xPercent: -100 * ( panels.length - 1 ),
	ease: "none",
	scrollTrigger: {
		trigger: "#panels-container",
		pin: true,
		start: "top top",
		scrub: 1,
		snap: {
			snapTo: 1 / (panels.length - 1),
			inertia: false,
			duration: {min: 0.1, max: 0.1}
		},
		end: () =>  "+=" + (panelsContainer.offsetWidth - innerWidth)
	}
});

}

function newScroll(){

	let height = (window.innerHeight) * 0.88;
	//console.log(height);

 	var controller = new ScrollMagic.Controller();

		// define movement of panels
		var wipeAnimation = new TimelineMax()
			// animate to second panel
			.to("#divExtra", 0.0, {height: height})
			.to("#slideContainer", 0.01, {z: -150})		// move back in 3D space 	
			.to("#slideContainer", 1,   {x: "-27%"})	// move in to first panel
			.to("#slideContainer", 0.01, {z: 0})				// move back to origin in 3D space
			.to("#divExtra", 0.0, {height: '0px'})
		new ScrollMagic.Scene({
				triggerElement: "#pinContainer",
				triggerHook: 0,
				duration: "300%"
			})
			.setPin("#pinContainer")
			.setTween(wipeAnimation)
			.addTo(controller);
}

function newScrollMobile(){

	let height = (window.innerHeight) * 2.4;
	//console.log(height);

 	var controller01 = new ScrollMagic.Controller();

		// define movement of panels
		var wipeAnimation01 = new TimelineMax()
			// animate to second panel
			.to("#divExtra", 0.0, {height: height})
			.to("#slideContainerMobile", 0.0, {paddingTop: "3%"})
			.to("#slideContainerMobile", 0.01, {z: -150})		// move back in 3D space 	
			.to("#slideContainerMobile", 1,   {x: "-51%"})	// move in to first panel
			.to("#slideContainerMobile", 0.01, {z: 0})				// move back to origin in 3D space
			.to(".panel", 0.0, {paddingBottom: "0"})
			.to("#pinContainerTablet", 0.0, { paddingBottom: "0" })
			.to("#divExtra", 0.0, {height: '0px'})
		new ScrollMagic.Scene({
				triggerElement: "#pinContainerMobile",
				triggerHook: 0,
				duration: "200%"
			})
			.setPin("#pinContainerMobile")
			// .addIndicators()
			.setTween(wipeAnimation01)
			.addTo(controller01);
}

function newScrollTablet(){

	let height = (window.innerHeight) * 1.2;
	//console.log(height);

 	var controller01 = new ScrollMagic.Controller();

		// define movement of panels
		var wipeAnimation01 = new TimelineMax()
			// animate to second panel
			.to("#divExtra", 0.0, {height: height})
			.to("#slideContainerTablet", 0.0, {paddingTop: "3%"})
			.to("#slideContainerTablet", 0.01, {z: -150})		// move back in 3D space 	
			.to("#slideContainerTablet", 1,   {x: "-10%"})	// move in to first panel
			.to("#image2", 1, { scale: 1, ease: Sine.easeInOut })
			.to("#slideContainerTablet", 2,   {x: "-20%"})
			.to("#slideContainerTablet", 1,   {x: "-26%"})
			.to("#slideContainerTablet", 0.01, {z: 0})				// move back to origin in 3D space
			.to(".panel", 0.0, {paddingBottom: "0"})
			.to("#divExtra", 0.0, {height: '0px'})
		new ScrollMagic.Scene({
				triggerElement: "#pinContainerTablet",
				triggerHook: 0,
				duration: "200%"
			})
			.setPin("#pinContainerTablet")
			// .addIndicators()
			.setTween(wipeAnimation01)
			.addTo(controller01);
}

function ObturadorMobile(){
	var controller2 = new ScrollMagic.Controller();

	let height = (window.innerHeight) * 1.6;
	let heightDiv = document.getElementById("pinObturadorMobile").offsetHeight;
	
	var wipeAnimation2 = new TimelineMax()
		.to("#myVideo", 0.0, {height: "auto"})
		.to("#divObturador", 0.0,{height: height})
		// .to("#divReto", 0.0, {marginTop: "-"+height+"px"})
		.to("#slideObturadorMobile", 0.0, {z: -150})		
		// .to("#divReto", 0.0, {marginTop: "-"+(height-heightDiv)+"px"})
		.to("#slideObturadorMobile", 0.8,   {opacity: "0"})
		.to("#slideObturadorMobile", 0.1,   {x: "-29%"})
		.to("#slideObturadorMobile", 0.8,   {opacity: "1"})
		.to("#slideObturadorMobile", 0.3, {z: 0})		
		
		.to("#slideObturadorMobile", 0.1, {z: -150})		
		.to("#slideObturadorMobile", 0.8,   {opacity: "0"})
		.to("#slideObturadorMobile", 0.1,   {x: "-58%"})
		.to("#slideObturadorMobile", 0.8,   {opacity: "1"})
		.to("#slideObturadorMobile", 0.3, {z: 0})		
		.to("#divObturador", 0.0,{height: (height/1.25)})
	// create scene to pin and link animation
	new ScrollMagic.Scene({
			triggerElement: "#pinObturadorMobile",
			triggerHook: 0,
			duration: "130%"
		})
		.setPin("#pinObturadorMobile", {
			pushFollowers: false
		})
		// .addIndicators()
		.setTween(wipeAnimation2)
		.addTo(controller2);
  }

  function ObturadorTablet(){
	var controller1 = new ScrollMagic.Controller();

	let height = (window.innerHeight) * 1.2;
	//console.log(height);
  
	var wipeAnimation1 = new TimelineMax()
		
		.to("#divObturador", 0.0,{height: height})
		.to(".about-us_div", 0.0, {paddingTop: "25px"})
		.to("#slideObturadorTablet", 0.1, {z: -150})		
		.to("#slideObturadorTablet", 0.8,   {opacity: "0"})
		.to("#slideObturadorTablet", 0.1,   {x: "-29%"})
		.to("#slideObturadorTablet", 0.8,   {opacity: "1"})
		.to("#slideObturadorTablet", 0.3, {z: 0})		
		
		.to("#slideObturadorTablet", 0.1, {z: -150})		
		.to("#slideObturadorTablet", 0.8,   {opacity: "0"})
		.to("#slideObturadorTablet", 0.1,   {x: "-58%"})
		.to("#slideObturadorTablet", 0.8,   {opacity: "1"})
		.to("#slideObturadorTablet", 0.3, {z: 0})	
		.to("#divObturador", 0.0,{height: 0})	

	// create scene to pin and link animation
	new ScrollMagic.Scene({
		triggerElement: "#pinObturadorTablet",
		triggerHook: 0,
		duration: "300%",
		offset: 50
	})
	.setPin("#pinObturadorTablet")
			.setTween(wipeAnimation1)
	.addTo(controller1);
}

function Obturador(){
	var controller1 = new ScrollMagic.Controller(/* {addIndicators: true} */);

	let height = (window.innerHeight);
	//console.log(height);

	let width = window.innerWidth;
	let heightScreen = window.innerHeight;
	var wipeAnimation1

	if( width >= 1300 && heightScreen != 1024 ){
	 	wipeAnimation1 = new TimelineMax()
		
		.to("#divObturador", 0.0,{height: height})
		.to("#slideObturador", 0.8,   {opacity: "0"})
		.to("#slideObturador", 0.1,   {x: "-29%"})
		.to("#slideObturador", 0.8,   {opacity: "1"})
		.to("#slideObturador", 0.3, {z: 0})		
		
		.to("#slideObturador", 0.1, {z: -150})		
		.to("#slideObturador", 0.8,   {opacity: "0"})
		.to("#slideObturador", 0.1,   {x: "-58%"})
		.to("#slideObturador", 0.8,   {opacity: "1"})
		.to("#slideObturador", 0.3, {z: 0})	
		.to("#divObturador", 0.0,{height: 0})	
	}

	if( width >= 1300 && heightScreen == 1024 ){
		wipeAnimation1 = new TimelineMax()
	   
	   .to("#divObturador", 0.0,{height: height})
	   .to("#slideObturador", 0.01, {z: -150})	
	   .to("#slideObturador", 0.8,   {opacity: "0"})
	   .to("#slideObturador", 0.1,   {x: "-29%"})
	   .to("#slideObturador", 0.8,   {opacity: "1"})
	   .to("#slideObturador", 0.3, {z: 0})		
	   
	   .to("#slideObturador", 0.1, {z: -150})		
	   .to("#slideObturador", 0.8,   {opacity: "0"})
	   .to("#slideObturador", 0.1,   {x: "-58%"})
	   .to("#slideObturador", 0.8,   {opacity: "1"})
	   .to("#slideObturador", 0.3, {z: 0})	
	   .to(".about-us_div", 0.0, {marginTop: "0%"})
	   .to("#divObturador", 0.0,{height: 0})	
   }
	if( width >= 1200 && width < 1300){
		wipeAnimation1 = new TimelineMax()
		
		.to("#divObturador", 0.0,{height: height})
		.to("#slideObturador", 0.01, {z: -150})		
		.to("#slideObturador", 0.8,   {opacity: "0"})
		.to("#slideObturador", 0.1,   {x: "-29%"})
		.to("#slideObturador", 0.8,   {opacity: "1"})
		.to("#slideObturador", 0.3, {z: 0})		
		
		.to("#slideObturador", 0.1, {z: -150})		
		.to("#slideObturador", 0.8,   {opacity: "0"})
		.to("#slideObturador", 0.1,   {x: "-58%"})
		.to("#slideObturador", 0.8,   {opacity: "1"})
		.to("#slideObturador", 0.3, {z: 0})	
		.to("#divObturador", 0.0,{height: 0})	

	}

	if( width >= 1024 && width < 1200){
		wipeAnimation1 = new TimelineMax()
		
		.to("#divObturador", 0.0,{height: height})
		.to(".about-us_div", 0.0, {paddingTop: "25px"})
		.to(".about-us_div", 0.0, {marginTop: "30px"})
		.to("#slideObturador", 0.01, {z: -150})		
		.to(".about-us_div", 0.0, {marginTop: "18%"})
		.to("#slideObturador", 0.8,   {opacity: "0"})
		.to("#slideObturador", 0.1,   {x: "-29%"})
		.to("#slideObturador", 0.8,   {opacity: "1"})
		.to("#slideObturador", 0.3, {z: 0})		
		
		.to("#slideObturador", 0.1, {z: -150})		
		.to("#slideObturador", 0.8,   {opacity: "0"})
		.to("#slideObturador", 0.1,   {x: "-58%"})
		.to("#slideObturador", 0.8,   {opacity: "1"})
		.to("#slideObturador", 0.3, {z: 0})	
		.to(".about-us_div", 0.0, {marginTop: "30px"})
		.to("#divObturador", 0.0,{height: 0})	

	}

	if( width >= 991 && width < 1024){
		wipeAnimation1 = new TimelineMax()
		
		.to("#divObturador", 0.0,{height: height})
		.to(".about-us_div", 0.0, {paddingTop: "2%"})
		.to(".about-us_div", 0.0, {paddingBottom: "5%"})
		.to(".about-us_div", 0.0, {marginTop: "40px"})
		.to("#slideObturador", 0.01, {z: -150})		
		.to(".about-us_div", 0.0, {marginTop: "28%"})
		.to(".imgB", 0.0, {bottom: "-6%"})
		.to("#slideObturador", 0.8,   {opacity: "0"})
		.to("#slideObturador", 0.1,   {x: "-29%"})
		.to("#slideObturador", 0.8,   {opacity: "1"})
		.to("#slideObturador", 0.3, {z: 0})		
		
		.to("#slideObturador", 0.1, {z: -150})		
		.to("#slideObturador", 0.8,   {opacity: "0"})
		.to("#slideObturador", 0.1,   {x: "-58%"})
		.to("#slideObturador", 0.8,   {opacity: "1"})
		.to("#slideObturador", 0.3, {z: 0})	
		.to(".about-us_div", 0.0, {marginTop: "35px"})
		.to("#divObturador", 0.0,{height: 0})	
	}

	if( (width >= 768 && width < 991 ) && heightScreen < 900 ){
		wipeAnimation1 = new TimelineMax()
		
		.to("#divObturador", 0.0,{height: height})
		.to(".about-us_div", 0.0, {paddingTop: "3%"})
		.to(".about-us_div", 0.0, {paddingBottom: "5%"})
		.to(".about-us_div", 0.0, {marginTop: "55px"})
		.to("#slideObturador", 0.01, {z: -150})		
		.to(".about-us_div", 0.0, {marginTop: "42%"})
		.to(".imgB", 0.0, {bottom: "0%"})
		.to("#slideObturador", 0.8,   {opacity: "0"})
		.to("#slideObturador", 0.1,   {x: "-29%"})
		.to("#slideObturador", 0.8,   {opacity: "1"})
		.to("#slideObturador", 0.3, {z: 0})		
		
		.to("#slideObturador", 0.1, {z: -150})		
		.to("#slideObturador", 0.8,   {opacity: "0"})
		.to("#slideObturador", 0.1,   {x: "-58%"})
		.to("#slideObturador", 0.8,   {opacity: "1"})
		.to("#slideObturador", 0.3, {z: 0})	
		.to(".about-us_div", 0.0, {marginTop: "35px"})
		.to("#divObturador", 0.0,{height: 0})	
	}

	if( (width >= 768 && width < 991 ) && heightScreen >= 900 ){
		wipeAnimation1 = new TimelineMax()
		
		.to("#divObturador", 0.0,{height: height})
		.to(".about-us_div", 0.0, {paddingTop: "3%"})
		.to(".about-us_div", 0.0, {paddingBottom: "5%"})
		.to(".about-us_div", 0.0, {marginTop: "55px"})
		.to("#slideObturador", 0.01, {z: -150})		
		.to(".about-us_div", 0.0, {marginTop: "55%"})
		.to(".imgB", 0.0, {bottom: "10%"})
		.to("#slideObturador", 0.8,   {opacity: "0"})
		.to("#slideObturador", 0.1,   {x: "-29%"})
		.to("#slideObturador", 0.8,   {opacity: "1"})
		.to("#slideObturador", 0.3, {z: 0})		
		
		.to("#slideObturador", 0.1, {z: -150})		
		.to("#slideObturador", 0.8,   {opacity: "0"})
		.to("#slideObturador", 0.1,   {x: "-58%"})
		.to("#slideObturador", 0.8,   {opacity: "1"})
		.to("#slideObturador", 0.3, {z: 0})	
		.to(".about-us_div", 0.0, {marginTop: "35px"})
		.to("#divObturador", 0.0,{height: 0})	
	}

	// create scene to pin and link animation
	new ScrollMagic.Scene({
		triggerElement: "#pinObturador",
		triggerHook: 0,
		duration: "250%"
	})
	.setPin("#pinObturador")
			.setTween(wipeAnimation1)

	.addTo(controller1);
}

function fnImgMontageReveal(e, t) {
    t = $(t);
    var o = $("figure", t).outerWidth(),
        i = new TimelineMax(),
        n = new ScrollMagic.Controller(),
        a = t.outerHeight() / 2 - 40;
    if ($(window).width() >= 1024 && $(window).width() > $(window).height()) {
        i.to("section.img-montage-reveal figure", 0.5, { x: -2 * o, scale: 1, ease: Sine.easeInOut }, 0)
            .to("section.img-montage-reveal figure:not(:last-of-type)", 0.5, { opacity: 0, ease: Sine.easeInOut }, 0)
            .from("section.img-montage-reveal .content", 0.1, { x: "+=20", opacity: 0, ease: Linear.easeNone }, 0.45);
        new ScrollMagic.Scene({ triggerElement: "section.img-montage-reveal", offset: a, duration: 3 * o }).setPin("section.img-montage-reveal").setTween(i).addTo(n);
    }
}