function myFunction(e){
    let wd = document.body.clientWidth;
    if( wd < 500 )
        $('#rotata').rotata({'transitionTime':'3s','autoPlay':true, circleRad: 40, circleCenterY: 30, circleCenterX: 100});
    if( wd >= 500 && wd < 768 )
        $('#rotata').rotata({'transitionTime':'3s','autoPlay':true, circleRad: 60, circleCenterY: 45 });
    if( wd >= 768 && wd < 990 )
        $('#rotata').rotata({'transitionTime':'3s','autoPlay':true, circleRad: 100 });
    if( wd >= 990 && wd < 1200 )
        $('#rotata').rotata({'transitionTime':'3s','autoPlay':true, circleRad: 120, circleCenterY: 80 });
    if( wd >= 1200 )
        $('#rotata').rotata({'transitionTime':'3s','autoPlay':true, circleRad: 180, circleCenterY: 80 });
}

(function (e) {
    var t = { rotate: false, circleCenterX: 50, circleCenterY: 50, circleRad: 150, autoPlay: false, transitionTime: "1s" };
    var n = function (n, r) {
        var i = n;
        this.config = e.extend({}, t, r);
        this.element = n;
        elementInAction = this.element;
        obj = this;
        this.init();
    };
    var r = {};
    var s = 0;
    n.prototype.init = function () {
        if (e(".rotateBackRotata").length == 0) {
            obj.createButtons();
        }
        var t = this.config;
        var s = this.element.children().length;
        var o = [];
        var u = 400/ 6;
        for (i = 1; i <= s; i++) {
            radian = i * u * (Math.PI / 180);
            o.push(radian);
        }
        if (!t.rotate) {
            e(elementInAction)
                .children()
                .each(function (n, i) {
                    xpos = t.circleCenterX + t.circleRad * Math.cos(o[n])+30;
                    ypos = t.circleCenterY + t.circleRad * Math.sin(o[n])-30;
                    if( n == 0 ){
                        if( t.circleRad == 40 ){
                            xpos -= 15;     
                            ypos += 20;
                        }
                        if( t.circleRad == 120 ){
                            xpos -= 50;     
                            ypos += 80;
                        }
                        if( t.circleRad == 60 ){
                            xpos -= 20;     
                            ypos += 20;
                        }
                        if( t.circleRad == 80 ){
                            xpos -= 25;     
                            ypos += 40;
                        }
                        if( t.circleRad == 100 ){
                            xpos -= 40;     
                            ypos += 60;
                        }
                        if( t.circleRad == 180 ){
                            xpos -= 35;     
                            ypos += 80;
                        }
                    }
                    r["elem" + n + "xpos"] = xpos;
                    r["elem" + n + "ypos"] = ypos;
                    e(this).css({ left: xpos + "px", top: ypos + "px" })
                });
            obj.assignZindex();
            obj.createReflection();
        }
        n.prototype.transitionProp = function (e) {
            e.css({ "-moz-transition-duration": t.transitionTime, "-webkit-transition-duration": t.transitionTime, "transition-duration": t.transitionTime });
        };
        n.prototype.rotateForward = function () {
            e(elementInAction)
                .children()
                .each(function (t, n) {
                    obj.transitionProp(e(this));
                    e(this).css("z-index", e(this).next().css("z-index"));
                    if (t == s - 1) {
                        e(this).css({ left: (r["elem" + 0 + "xpos"]) + "px", top: (r["elem" + 0 + "ypos"]) + "px" });
                        e(this).children().eq(0).css('display', 'none');
                        e(this).children().eq(1).css('display', 'block');
                    } else {
                        e(this).css({ left: (r["elem" + (t + 1) + "xpos"]) + "px", top:( r["elem" + (t + 1) + "ypos"]) + "px" });
                        e(this).children().eq(1).css('display', 'none');
                        e(this).children().eq(0).css('display', 'block');
                    }
                });
            e(elementInAction)
                .children()
                .eq(s - 1)
                .css("z-index", parseInt(e(elementInAction).children().last().css("z-index")) + 1);
            setTimeout(function () {
                e(elementInAction).children().last().prependTo(elementInAction);
                e(".rotateBackRotata,.rotateForwardRotata").removeAttr("disabled");
            }, parseInt(t.transitionTime) * 1e3);
        };
        n.prototype.rotateBackward = function () {
            e(elementInAction)
                .children()
                .each(function (t, n) {
                    e(this).css("z-index", e(this).prev().css("z-index"));
                    obj.transitionProp(e(this));
                    if (t == 0) {
                        e(this).css({ left: r["elem" + (s - 1) + "xpos"] + "px", top: r["elem" + (s - 1) + "ypos"] + "px" });
                    } else {
                        e(this).css({ left: r["elem" + (t - 1) + "xpos"] + "px", top: r["elem" + (t - 1) + "ypos"] + "px" });
                    }
                });
            e(elementInAction)
                .children()
                .first()
                .css("z-index", parseInt(e(elementInAction).children().eq(1).css("z-index")));
            setTimeout(function () {
                e(elementInAction).children().first().appendTo(elementInAction);
                e(".rotateBackRotata,.rotateForwardRotata").removeAttr("disabled");
            }, parseInt(t.transitionTime) * 1e3);
        };
    };
    n.prototype.createButtons = function () {
        elementInAction.wrap("<div class='rotataOuter'></div>");
        var t = elementInAction.children().first().width();
        elementInAction.parent().css({ width: "100%", position: "relative" });
        /* elementInAction.css({ "margin-left": t / 2 + "px" }); */
        e("<input/>", { class: "rotateBackRotata", type: "button", value: "<" }).appendTo(".rotataOuter");
        e("<input/>", { class: "rotateForwardRotata", type: "button", value: ">" }).appendTo(".rotataOuter");
    };
    n.prototype.createReflection = function () {
        /* elementInAction.children().each(function () {
            var t = e(this).clone();
            t.removeAttr("class style");
            //t.addClass("eachBitReflected");
            t.appendTo(e(this));
            t.css({ position: "absolute", transform: "rotateX(180deg)", "-moz-transform": "rotateX(180deg)", "-webkit-transform": "rotateX(180deg)", top: "100%", height: "100%", overflow: "hidden", "margin-top": "1px", opacity: ".1" });
        }); */
    };
    n.prototype.assignZindex = function () {
        var t = elementInAction.children().length;
        var n = 0;
        var r = elementInAction.children().first();
        elementInAction.children().each(function () {
            var t = parseInt(e(this).css("top"));
            if (t > n) {
                n = t;
                r = e(this);
            }
        });
        var i = elementInAction.children().index(r);
        var s = t * 10;
        elementInAction.children().eq(i).children().eq(0).css('display', 'none')
        elementInAction.children().eq(i).children().eq(1).css('display', 'block')
        elementInAction.children().eq(i).css("z-index", s);
        r.nextAll().each(function (n) {
            if (n < t / 2) {
                e(this).css("z-index", s - 10);
                s -= 10;
            } else {
                e(this).css("z-index", s + 10);
                s += 10;
            }
        });
        s = t * 10;
        r.prevAll().each(function (t) {
            e(this).css("z-index", s - 10);
            s -= 10;
        });
    };
    n.prototype.autoplay = function () {
        if (obj.config.autoPlay == true) {
            e(".rotateForwardRotata,.rotateBackRotata").hide();
            setInterval(function () {
                e(".rotateForwardRotata").trigger("click");
            }, parseInt(obj.config.transitionTime) * 1e3);
        }
    };
    e.fn.rotata = function (e) {
        new n(this, e);
        obj.autoplay();
        return this;
    };
    e(document).on("click", ".rotateBackRotata,.rotateForwardRotata", function () {
        if (e(this).hasClass("rotateBackRotata")) {
            e(this).attr("disabled", "disabled");
            obj.rotateBackward();
        }
        if (e(this).hasClass("rotateForwardRotata")) {
            e(this).attr("disabled", "disabled");
            obj.rotateForward();
        }
        var t = Object.create(r);
    });
})(jQuery);